import React, { useRef } from 'react';

import Container from '../components/Container';
import Hero from '../components/Hero';
import Banner from '../components/Banner';
import ThemeLink from '../components/ThemeLink';
import Layout from '../components/Layout/Layout';

import * as styles from './tcherbs.module.css';
const TcherbsPage = (props) => {
  let historyRef = useRef();
  let valuesRef = useRef();
  let sustainabilityRef = useRef();

  const handleScroll = (elementReference) => {
    if (elementReference) {
      window.scrollTo({
        behavior: 'smooth',
        top: elementReference.current.offsetTop - 280,
      });
    }
  };

  return (
    <Layout disablePaddingBottom title={"TC Herbs & Health"}>
      <div className={styles.root}>
        {/* Hero Container */}
        <Banner
          maxWidth={'900px'}
          height={'260px'}
          bgImage={'/tcherbs/herbs_drawers.jpg'}
          name={`TC Herbs & Health \n `}
          color={'var(--standard-white)'}
        />

        {/* <div className={styles.navContainer}>
          <ThemeLink onClick={() => handleScroll(historyRef)} to={'#history'}>
            History
          </ThemeLink>
          <ThemeLink onClick={() => handleScroll(valuesRef)} to={'#values'}>
            Values
          </ThemeLink>
          <ThemeLink
            onClick={() => handleScroll(sustainabilityRef)}
            to={'#sustainability'}
          >
            Sustainability
          </ThemeLink>
        </div> */}

        {/* <Container size={'large'} spacing={'min'}>
          <div className={styles.detailContainer} ref={historyRef}>
            <p>
              Founded in 1860, Sydney is an innovative British brand with a
              contemporary edge. We make timeless everyday luxury clothing.
            </p>
            <br />
            <br />
            <p>
              We created some of the world's first T-shirts and spent decades
              perfecting the feel of the cotton. Today we are the only brand
              that makes T-shirts in its own factory in the UK. And we do this
              in the same factory we have occupied since 1937.
            </p>
          </div>
        </Container> */}

        <div className={styles.imageContainer}>
          <img alt={'TC Herbs'} src={'/tcherbs/storeFront.jpg'} width="50%"></img>
        </div>

        <Container size={'large'} spacing={'min'}>
          <div className={styles.content}>
            <h3>About TC Herbs & Health</h3>
            <div ref={valuesRef}>
              <p>
              TC Herbs & Health is a unique Integrative Medicine Center with a wider-angle of specialties. The Integrative Medicine center combines the best of alternative and complementary treatments with allied health professionals and alternative practitioners. We provide over three thousand varieties of the highest quality Traditional Chinese, Western, and Aromatic herbs. 
              </p>
              <p>
                TC Herbs also offers homeopathic remedies, nutritional products, vitamins, and herbal teas. In addition to Traditional Chinese remedies we provide herbal consultations, acupuncture, traditional massage (Tui Na), and Tai Chi Chuan. 
                <br/>
                <br/>
                We offer herbal medicine in many forms: bulk, decoction, ground powder, concentrate extract , tea bags, tablets, pills, soft-gels, patches, creams, extracted oils, and essence oils. 

                <br />
                <br />
                Our team members are respected and well known. They are highly educated individuals with a strong background in herbal medicine, western medicine and acupuncture. The staff is knowledgeable with many years of clinical service and experience.
              </p>
              
              <img alt={'TC Herbs & Health Store Interior'} src={'/tcherbs/storeInside3.jpg'}></img>
            </div>
          </div>
        </Container>
        <div className={styles.imageContainer}>
          <img alt={'TC Herbs Collection'} src={'/tcherbs/herbs.jpg'}></img>
        </div>
      </div>
    </Layout>
  );
};

export default TcherbsPage;
